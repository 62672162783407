/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, canonical }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: "Brevan Howard - Global Alternative Investment Management",
        },
        {
          property: `og:description`,
          content:
            "Brevan Howard is a leading global alternative investment management platform, specializing in macro strategies for institutional and private investors worldwide.",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://www.brevanhoward.com/`,
        },
        {
          property: `og:image`,
          content: `https://www.brevanhoward.com/static/ph-home-hero-1efde5d0d12be42e803cbd1420a4bd40.jpg`,
        },
        {
          property: `og:site_name`,
          content: `Brevan Howard`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {` <!-- Google Tag Manager --> `}
      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TRR4Z29');
        `}
      </script>
      {` <!-- End Google Tag Manager --> `}

      {` <!-- Google tag (gtag.js) --> `}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-8NMNVD4EXM"
      />

      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-8NMNVD4EXM');
        `}
      </script>

      <script type="application/ld+json">
        {`
          "@context": "http://schema.org",
          "@type": "LocalBusiness",
          "name": "Brevan Howard"
        `}
      </script>

      {` <!-- ProFundCom Tracker --> `}
      <script
        type="application/javascript"
        src="https://brevanhoward.profundcom.net/dms/w.php"
        referrerpolicy="no-referrer-when-downgrade"
      ></script>
      {` <!-- ProFundCom Tracker End --> `}

      {` <!-- Canonical Tag --> `}
      <link rel="canonical" href={canonical} />
      {` <!-- Canonical Tag End --> `}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
